import {http, param} from '../../utils/http'
import conf from '../../config'
import fileDownload from 'js-file-download'

export const API_ROOT = `${conf.API_URL}`

const LocationService = {
    async findSaleRep(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'status', val: payload.status ?  (payload.status.trim() == 'Not Covered' ? 'NotCovered' : (payload.status.trim() == 'not covered' ? 'NotCovered'  : payload.status.trim())) : '' },
            { query: 'filterModel', val: payload.filterModel ?  encodeURIComponent(JSON.stringify(payload.filterModel))  : null },
        ]
        return http.get(`${API_ROOT}/supplier/salesrep${param(arr)}`).then(resp => {
            return resp
        })
    },
    async createSalesRep(payload) {
        return http.post(`${API_ROOT}/supplier/salesrep`, payload).then(resp => {
            return resp
        })
    },
    async getSalesRep(id) {
        return http.get(`${API_ROOT}/supplier/salesrep/${id}`).then(resp => {
            return resp
        })
    },
    uploadFile (formData) {
        return http.post(`${API_ROOT}/supplier/salesrep/upload-salesrep-file`, formData).then(resp => {
            return resp
        })
    },
    async changeStatusForSalesRep(id,status) {
        return http.get(`${API_ROOT}/supplier/salesrep/${id}/${status}`).then(resp => {
            return resp
        })
    },
    downloadSupplierLocationReportReport(extenstionType) {
        return http.get(`${API_ROOT}/supplier/salesrep/SupplierSalesRepReport/${extenstionType}`,{ responseType: 'blob' }).then(resp => {

            fileDownload(resp.data, `Sales Representatives Report.${extenstionType}`,'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        })
    },
}

export default LocationService
