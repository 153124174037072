import locationService from '../../../../services/supplier/location'
import saleRepService from '../../../../services/supplier/sales-rep'
export default {
    data() {
        return {
            item: {
                first_name: null,
                last_name: null,
                email_address: null,
                title: null,
                account_name: null,
                account_number: null,
                idn: null,
                territory: null,
                region: null,
                sales_rep_details: []
            },
            accounts: [],
            initialAccounts: [],
            formType: 'INSERT',
            labelName: {
                first_name: 'First Name *',
                last_name: 'Last Name *',
                email_address: 'Email *',
                title: 'Title *',
                account_name: 'Account Name',
                account_number: 'Account Number *',
                idn: 'Account Number *',
                territory: 'Territory',
                region: 'Region'
            },
        }
    },
    computed: {
        form(){
            if(this.formType == 'INSERT'){
                this.initialAccounts.push({
                    account_name: null,
                    account_number: null,
                    idn: null,
                    order: null,
                })
                return {
                    title: 'New Sales Representative',
                    isDisabled: false
                }
            }
            if(this.formType == 'UPDATE'){
                return {
                    title: 'Update Sales Representative',
                    isDisabled: true
                }
            }
            if(this.formType == 'VIEW'){
                return {
                    title: 'View Sales Representative',
                    isDisabled: true
                }
            }
        }
    },
    methods: {
        forceRerenderInsert() {
            this.formType = 'INSERT'
            this.$refs.modal.show()
        },
        forceRerenderUpdate(id,mode) {
            this.formType = mode
            this.getSalesRep(id)
        },
        async save() {
            var isValid = await this.$refs['observer'].validate()
            if(isValid && this.formType == 'INSERT') {
                this.createLocation()
            }
            if(isValid && this.formType == 'UPDATE'){
                this.createLocation()
            }
        },
        removeAction(item) {
            let accList = []
            if (this.accounts && this.accounts.length > 0) {
                this.accounts.forEach(x => {
                    if((x.account_name.trim() + x.account_number + x.idn) != (item.account_name + item.account_number + item.idn)){
                        accList.push(x)
                    }
                })
                this.accounts = accList
            }
        },
        SaveAction(item) {
            let accList = []
            if (this.accounts && this.accounts.length > 0) {
                this.accounts.forEach(x => {
                    x.disabled = true
                    accList.push(x)
                })
                this.accounts = accList
            }
        },
        EditAction(item) {
            this.accounts.forEach(x => {
                x.disabled = true
            })
            let accList = []
            if (this.accounts && this.accounts.length > 0) {
                this.accounts.forEach(x => {
                    if(x.account_name == item.account_name && x.account_number == item.account_number && x.idn == item.idn){
                        x.disabled = false
                    }
                    accList.push(x)
                })
                this.accounts = accList
            }
        },
        addAction() {
            if(this.initialAccounts[0].account_name != null && this.initialAccounts[0].account_number != null && this.initialAccounts[0].idn != null)
            {
                let iserror = false
                this.accounts.forEach(item => {
                    if(this.initialAccounts[0].account_name == item.account_name && this.initialAccounts[0].account_number == item.account_number && this.initialAccounts[0].idn == item.idn){
                        iserror = true
                    }
                })
                if(!iserror){
                    this.accounts.push({
                        account_name: this.initialAccounts[0].account_name,
                        account_number: this.initialAccounts[0].account_number,
                        idn: this.initialAccounts[0].idn,
                        disabled: true
                    })
                    requestAnimationFrame(() => {
                        this.$refs.observerAddAction.reset()
                    })
                    this.initialAccounts = []
                    if(this.formType == 'UPDATE')
                    {
                        this.initialAccounts.push({
                            account_name: null,
                            account_number: null,
                            idn: null,
                            order: null,
                        })
                    }
                }
                else{
                    this._showToast('Account already added!', { variant: 'danger', title: 'Error' })
                }
            }
        },
        updateLocation() {
            locationService.updateLocation(this.item).then(resp =>{
                if (!resp.error){
                    this.$emit('onCompleted', 'UPDATE')
                    this.$refs.modal.hide()
                }
            })
        },
        createLocation() {
            this.item.sales_rep_details = []
            if(this.initialAccounts[0].account_name != null && this.initialAccounts[0].account_number != null && this.initialAccounts[0].idn != null)
            {
                this.item.sales_rep_details.push(...this.initialAccounts)
            }
            if(this.accounts.length > 0)
            {
                this.item.sales_rep_details.push(...this.accounts)
            }
            saleRepService.createSalesRep(this.item).then(resp =>{
                if (!resp.error){
                    this.$emit('onCompleted', 'INSERT')
                    this.$refs.modal.hide()
                }
            })
        },
        getSalesRep(id) {
            saleRepService.getSalesRep(id).then(resp =>{
                if (!resp.error){
                    this.item = resp.data.d
                    this.accounts = resp.data.d.sales_rep_details
                    this.accounts.forEach(x => {
                        x.disabled = true
                    })
                    this.$nextTick(()=>{
                        this.$refs.modal.show()
                    })
                }
            })
        }
    }
}